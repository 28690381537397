// export default [
//   {
//     header: 'Order Management',
//   },
//   {
//     title: 'Amazon Order',
//     icon: 'FileTextIcon',
//     children: [
//       {
//         title: 'Order',
//         route: 'amazon-job',
//         // icon: 'CopyIcon',
//       },
//       {
//         title: 'Pre Alert',
//         route: 'pre-alert',
//         // icon: 'PackageIcon',
//       },
//       // {
//       //   title: 'Return',
//       //   route: 'amazon-return',
//       // },
//       {
//         title: 'Invoice List',
//         route: 'invoice-list',
//       },
//     ],
//   },
//   {
//     title: 'AMZ Invoice',
//     icon: 'HexagonIcon',
//     children: [
//       {
//         title: 'Invoice List',
//         route: 'invoice-list',
//       },
//     ],
//   },
//   {
//     title: 'Last Mile',
//     icon: 'BoxIcon',
//     children: [
//       {
//         title: 'Order List',
//         route: 'orderList',
//       },
//       {
//         title: 'Pickup Request',
//         route: 'pickup-request',
//       },
//       {
//         title: 'Upload Order',
//         route: 'upload-order',
//       },
//     ],
//   },
//   {
//     title: 'Fulfillment',
//     icon: 'BriefcaseIcon',
//     children: [
//       {
//         title: 'Inbound Order',
//         route: 'inbound',
//       },
//       {
//         title: 'Outbound Order',
//         route: 'outbound',
//       },
//       {
//         title: 'SKU Master',
//         route: 'skuMaster',
//       },
//       {
//         title: 'Sales Order',
//         route: 'salesOrder',
//       },
//     ],
//   },
//   {
//     title: 'Operation',
//     icon: 'TrelloIcon',
//     children: [
//       {
//         title: 'Operation',
//         route: 'operation',
//       },
//       {
//         title: 'cus Order Update',
//         route: 'cus-order-update',
//       },
//       {
//         title: 'Batch Update Status',
//         route: 'batch-update',
//       },
//     ]
//   },
//   {
//     title: 'Report',
//     icon: 'LayersIcon',
//     children: [
//       {
//         title: 'CUS order Inbound Report',
//         route: 'inbound-report',
//       },
//       {
//         title: 'Label Batch Download',
//         route: 'batch-download',
//       },
//     ]
//   },
// ]
export default [
  {
    header: 'Amazon Order',
  },
  {
    title: 'Order',
    route: 'amazon-job',
    icon: 'FileTextIcon',
  },
  {
    title: 'Pre Alert',
    route: 'pre-alert',
    icon: 'BellIcon',
  },
  {
    title: 'Invoice List',
    route: 'invoice-list',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Chargeable Order',
    route: 'charge-table',
    icon: 'DollarSignIcon',
  },
  {
    header: 'Delivery Order',
  },
  {
    title: 'Order List',
    route: 'orderList',
    icon: 'FileTextIcon',
  },
  {
    title: 'PY-Waybill',
    route: 'PY-Waybill',
    icon: 'ArrowUpCircleIcon',
  },
  {
    title: 'Pickup Request',
    route: 'pickup-request',
    icon: 'BoxIcon',
  },
  {
    title: 'Regular Pick up',
    route: 'regular-pickup',
    icon: 'LogInIcon',
  },
  {
    title: 'COD FOD List',
    route: 'cod-fod-list',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Upload Order',
    route: 'upload-order',
    icon: 'ArrowUpCircleIcon',
  },
  {
    title: 'Batch Update Operation',
    route: 'batch-update',
    icon: 'EditIcon',
  },
  {
    header: 'Fulfillment',
  },
  {
    title: 'Inbound Order',
    route: 'inbound',
    icon: 'ArrowDownLeftIcon',
  },
  {
    title: 'Outbound Order',
    route: 'outbound',
    icon: 'ArrowUpRightIcon',
  },
  {
    title: 'SKU Master',
    route: 'skuMaster',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Sales Order',
    route: 'salesOrder',
    icon: 'FileTextIcon',
  },
  {
    title: 'ASN',
    route: 'asn',
    icon: 'LogInIcon',
  },
  {
    title: 'Inventory listing',
    route: 'inventory',
    icon: 'ListIcon',
  },
  {
    header: 'CUS Operation',
  },
  {
    title: 'CUS Tracking',
    route: 'operation',
    icon: 'TrelloIcon',
  },
  {
    title: 'CUS Order Update',
    route: 'cus-order-update',
    icon: 'Edit3Icon',
  },
  {
    header: 'Report',
  },
  {
    title: 'CUS order Inbound Report',
    route: 'inbound-report',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Label Batch Download',
    route: 'batch-download',
    icon: 'ChevronDownIcon',
  },
  {
    title: 'Signature Batch Download',
    route: 'batch-download-signature',
    icon: 'ChevronDownIcon',
  },
  {
    title: 'Download Report',
    route: 'download-report',
    icon: 'DownloadIcon',
  },
  {
    title: 'Download Invoice',
    route: 'download-invoice',
    icon: 'DownloadIcon',
  },
  {
    header: 'Business Profile',
  },
  {
    title: 'Company List',
    icon: 'LifeBuoyIcon',
    route: 'customer-list',
  },
  {
    title: 'Company List',
    icon: 'LifeBuoyIcon',
    route: 'customer-list2',
  },
  // {
  //   title: 'Merchant Label',
  //   icon: 'LifeBuoyIcon',
  //   route: 'customer-list',
  // },
  {
    header: 'Config Center',
  },
  {
    title: 'Master Data',
    icon: 'UserIcon',
    children: [
      {
        title: 'Master Code Data',
        route: 'master-code-data',
      },
      {
        title: 'Self-pick List',
        route: 'self-pick',
      },
    ],
  },
  {
    title: 'User',
    icon: 'UsersIcon',
    children: [
      {
        title: 'User List',
        route: 'user-list',
      },
      {
        title: 'Role',
        route: 'role-list',
      },
    ],
  },
  {
    title: 'Configuration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Internal Status Code',
        route: 'status-code',
      },
      {
        title: 'External Status Code',
        route: 'external-code',
      },
      {
        title: 'Service Route',
        route: 'service-route',
      },
      {
        title: 'SLA',
        route: 'sla-list',
      },
      {
        title: 'Auto report',
        route: 'auto-report',
      },
      {
        title: 'Invoice',
        route: 'invoice',
      },
    ],
  },
]